import React from 'react';
import PropTypes from 'prop-types';

const ButtonWithIcon = ({
  text,
  onClick,
  className,
  leftSide = null,
  rightSide = null,
}) => (
  <button
    className={`${className} text-gray-700 hover:text-gray-800 py-2.5 px-3.5 rounded-3p shadow-sm border border-gray-light text-center whitespace-nowrap`}
    onClick={onClick}
  >
    {leftSide}
    {text}
    {rightSide}
  </button>
);
export default ButtonWithIcon;

ButtonWithIcon.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  leftSide: PropTypes.node,
  rightSide: PropTypes.node,
};
