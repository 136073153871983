import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const pp = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export const H1 = ({ className = '', children }) => (
  <h1 className={`font-medium heading ${className}`}>{children}</h1>
);
H1.propTypes = pp;

export const H15 = ({ className = '', children }) => (
  <h3 className={`${className} text-lg lg:text-3xl text-gray-700 font-medium`}>
    {children}
  </h3>
);
H15.propTypes = pp;

export const H2 = ({ className = '', children }) => (
  <h2
    className={`${className} font-medium text-2xl lg:text-5xl text-gray-900 mb-5 lg:leading-tight tracking-tight`}
  >
    {children}
  </h2>
);
H2.propTypes = pp;

export const H3 = ({ className = '', children }) => (
  <h3 className={` text-lg lg:text-xl text-gray-700 ${className}`}>
    {children}
  </h3>
);
H3.propTypes = pp;

export const H4 = ({ className = '', children }) => (
  <h3 className={`${className} font-medium text-2xl lg:text-4xl text-gray-900`}>
    {children}
  </h3>
);
H4.propTypes = pp;
