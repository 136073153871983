import * as React from 'react';
import { Link, navigate } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import brand from '../../../images/brand.svg';
import flatWheelTractor from '../../../images/flat_wheel_tractor.jpg';
import rightToLeftArrow from '../../../images/icons/right-to-left-arrow.svg';
import { H1, H3 } from '../../../components/Texts';
import PrimaryLink from '../../../components/Buttons/PrimaryLink';
import ButtonWithIcon from '../../../components/Buttons/ButtonWithIcon';

const pageStyles = {
  color: '#232129',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};

const containerStyles = {
  maxWidth: '1280px',
  alignItems: 'center',
};

const NotFoundPage = () => {
  const intl = useIntl();
  const goBack = () => navigate(-1);
  const home = intl.formatMessage({ id: 'links.home.url' });
  return (
    <main
      style={pageStyles}
      className="flex justify-center items-center md:p-24 h-screen"
    >
      <div
        style={containerStyles}
        className="pl-8 pr-8 pt-16 pb-16 md:pl-8 w-full h-full flex flex-col md:flex-row "
      >
        <div className="flex flex-col">
          <Link to={home}>
            <img src={brand} alt="Home" className="relative top-1" />
          </Link>

          <p className="text-primary-600 font-semibold mt-12 mb-3">
            {intl.formatMessage({ id: 'pages.404.error-code' })}
          </p>
          <H1 className="mb-6">
            {intl.formatMessage({ id: 'pages.404.title' })}
          </H1>
          <H3 className="mb-12 lg:w-4/5">
            {intl.formatMessage({ id: 'pages.404.description' })}
          </H3>

          <div className="flex lg:space-x-4 flex-col lg:flex-row space-y-4 lg:space-y-0">
            <ButtonWithIcon
              onClick={goBack}
              text={intl.formatMessage({ id: 'buttons.goBack' })}
              leftSide={
                <img className="inline mr-3.5" src={rightToLeftArrow} alt="" />
              }
            />
            <PrimaryLink
              to={home}
              text={intl.formatMessage({ id: 'buttons.goHome' })}
            />
          </div>
        </div>
        <img
          src={flatWheelTractor}
          alt=""
          className="max-h-sm max-w-sm lg:max-w-3xl lg:max-h-3xl "
        />
      </div>
    </main>
  );
};

export default NotFoundPage;
